@import url('attractionCard.css');
@import url('accommodationCard.css');

div#landingPage #carousellDescription .slick-slider .slick-regions {
    top: -35px;
    bottom: unset;
    width: unset;
    left: 20px;
    right: 20px;
}

div#landingPage #carousellDescription .carouselItemContentContainer {
    overflow-y: scroll; 
    height: 130px;
}

div#landingPage .carouselItemContentContainer span {
    color: white;
}

div#landingPage #carouselMobileDescription {
    position: relative; 
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 10px;
    z-index: 1;
    background-color: rgba(0,0,0,0.8);
    border-radius: 0px;
    padding: 10px;
    padding-bottom: 15px;
}

div#landingPage #carousellDescription {
    position: absolute;
    right: 10px;
    bottom: 10px; 
    z-index: 10;
    width: 400px;
    background-color: rgba(0,0,0,0.5);
    border-radius: 10px;
    padding: 10px;
}

div#landingPage .slick-list {
    z-index: 1;
}

div#landingPage .slick-slider .slick-promoAttractions, .slick-slider .slick-promoAccommodations {
    top: -19px;
    bottom: unset;
}

div#landingPage .slick-slider .slick-top-regions {
    top: unset;
    bottom: 8px;
    width: unset;
    left: 20px;
    right: 20px;
}

div#landingPage .slick-slider .slick-search {
    top: -20px;
}

div#landingPage .slick-regions li.slick-active button:before, .slick-top-regions li.slick-active button:before {
    color: white;
}

div#landingPage .slick-regions li button:before, .slick-top-regions li button:before {
    color: white
}

div#landingPage div.landingPageTopNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, 
div#landingPage div.landingPageTopNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, 
div#landingPage div.landingPageTopNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, 
div#landingPage div.landingPageTopNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, 
div#landingPage div.landingPageTopNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, 
div#landingPage div.landingPageTopNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, 
div#landingPage div.landingPageTopNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, 
div#landingPage div.landingPageTopNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: white;
}

div#landingPage div.landingPageTopNavigation > .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, 
div#landingPage div.landingPageTopNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, 
div#landingPage div.landingPageTopNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, 
div#landingPage div.landingPageTopNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, 
div#landingPage div.landingPageTopNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, 
div#landingPage div.landingPageTopNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, 
div#landingPage div.landingPageTopNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, 
div#landingPage div.landingPageTopNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom-color: white;
}

div#landingPage div.landingPageTopNavigation > .ant-menu-light .ant-menu-item:hover, 
div#landingPage div.landingPageTopNavigation .ant-menu-light .ant-menu-item-active, 
div#landingPage div.landingPageTopNavigation .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, 
div#landingPage div.landingPageTopNavigation .ant-menu-light .ant-menu-submenu-active, 
div#landingPage div.landingPageTopNavigation .ant-menu-light .ant-menu-submenu-title:hover,
div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal > .ant-menu-item span:hover {
    color: rgb(255, 255, 255);
}

div#landingPage div.landingPageLoginNavigation > .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, 
div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, 
div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, 
div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, 
div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, 
div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, 
div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, 
div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: white;
}

div#landingPage div.landingPageLoginNavigation > .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, 
div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, 
div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, 
div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, 
div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, 
div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, 
div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, 
div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom-color: white;
    border-bottom: 0px;
}

div#landingPage div.landingPageLoginNavigation > .ant-menu-light .ant-menu-item:hover, 
div#landingPage div.landingPageLoginNavigation .ant-menu-light .ant-menu-item-active, 
div#landingPage div.landingPageLoginNavigation .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, 
div#landingPage div.landingPageLoginNavigation .ant-menu-light .ant-menu-submenu-active, 
div#landingPage div.landingPageLoginNavigation .ant-menu-light .ant-menu-submenu-title:hover {
    color: white;
}

div#landingPage div.landingPageTopNavigation > .ant-menu-horizontal > .ant-menu-item a, 
div#landingPage div.landingPageTopNavigation > .ant-menu-horizontal > .ant-menu-submenu a {
    color: white;
}

div#landingPage div.landingPageTopNavigation ul li:first-child {
    padding-inline: 0px;
    padding-left: 16px;
}
  

div#landingPage div.landingPageLoginNavigation .ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: 0px;
}

.landingPageDrawer .ant-drawer-body {
    padding: 0px;
}

.landingPageDrawer .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 4px;
}

.landingPageDrawer .ant-drawer-body ul {
    padding: 0px;
    background-color: white;
}

.landingPageDrawer .ant-drawer-body ul li a {
    color: rgba(0, 0, 0, 0.85);
}

.landingPageDrawer .ant-drawer-body ul li.itemMobile a {
    font-size: 15px;
}

.landingPageDrawer .ant-drawer-body ul li.itemMobile span.anticon
{
    font-size: 16px;
}

.landingPageDrawer ul li.ant-menu-item-active .ant-menu-title-content a {
    color: #1890ff;
}

.landingPageDrawer ul li.ant-menu-submenu-active .ant-menu-submenu-title .ant-menu-title-content a {
    color: #1890ff;
}

.landingPageDrawer .ant-drawer-content {
    background-color: rgba(0,0,0,0.5);
}

.landingPageDrawer .ant-menu-inline, 
.landingPageDrawer .ant-menu-vertical, 
.landingPageDrawer .ant-menu-vertical-left {
    border-right: 0px;
}

/* promoAttractions */
div.promoAttractions {
    min-height: 265px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#landingPage.smartphoneDevice div.promoAttractions,
#landingPage.tabletDevice div.promoAttractions {
    background-color: white;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0px;
    display: unset;
    justify-content: unset;
    flex-wrap: unset;
}
/* end promoAttractions */

/* promoAccommodations */
div.promoAccommodations {
    min-height: 265px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#landingPage.smartphoneDevice div.promoAccommodations,
#landingPage.tabletDevice div.promoAccommodations {
    background-color: white;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0px;
    display: unset;
    justify-content: unset;
    flex-wrap: unset;
}
/* end promoAttractions */

.titlePromo
{
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 10px;
    color: rgba(0,0,0,0.6);
    font-size: 14px;
}

#landingPage.smartphoneDevice .titlePromo,
#landingPage.tabletDevice .titlePromo
{
    margin-bottom: 0px;
}

/* promo body */
#landingPage .promoContainerBody {
    background-color: white;
    flex: 1;
    display: flex;
    flex-direction: column; 
    position: relative;
    min-width: 360px;
    max-width: 80%;
}

#landingPage.smartphoneDevice .promoContainerBody, #landingPage.tabletDevice .promoContainerBody {
    max-width: unset;
}

#landingPage .promoContainerSubBody {
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 5px;
    padding-left: 5px; 
    padding-right: 5px;
    padding-bottom: 0px;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0, 0.05);
}

#landingPage.smartphoneDevice .promoContainerSubBody,
#landingPage.tabletDevice .promoContainerSubBody
{
    margin-bottom: 0px;
    border: unset;
}
/* end promo body */

div#landingPage div.promoAccommodations .ant-card-meta-detail > div:not(:last-child), 
div#landingPage div.promoAttractions .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0px;
    margin-top: -3px;
}

div#landingPage div.promoAccommodations .ant-tabs-tab-btn,
div#landingPage div.promoAccommodations .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn, 
div#landingPage div.promoAttractions .ant-tabs-tab-btn, 
div#landingPage div.promoAttractions .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: rgba(0, 0, 0, 0.7);
}

div#landingPage div.promoAttractions .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 600;
}

div#landingPage div.promoAccommodations .ant-tabs-tab:hover,
div#landingPage div.promoAccommodations .ant-tabs-tab .ant-tabs-tab-btn:active, 
div#landingPage div.promoAttractions .ant-tabs-tab:hover,
div#landingPage div.promoAttractions .ant-tabs-tab .ant-tabs-tab-btn:active {
    color: rgba(0, 0, 0, 0.5);
}

div#landingPage div.promoAttractions .ant-tabs-tab .ant-tabs-tab-btn:active {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 600;
}

div#landingPage div.promoAttractions .ant-tabs {
    line-height: 1.4;
}

div#landingPage div.promoAccommodations .ant-tabs {
    line-height: 1.4;
}

div#landingPage div.promoAccommodations .ant-tabs-ink-bar, .promoAttractions .ant-tabs-ink-bar {
    background-color: rgba(0, 0, 0, 0.1);
}

div#landingPage div.searchContainer {
    width: 18%;
    min-width: 290px;
    max-width: 350px;
}

div#landingPage .searchContainer .ant-tag-checkable {
    background-color: #fafafa;
}

div#landingPage .searchContainer .ant-tag-checkable-checked {
    background-color: #1890ff;
}

div#landingPage .searchContainer .ant-tag {
    margin-right: 3px;
}

div#landingPage #fullScreenContainer {
    width: "100%";
    z-index: 0;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
    overflow: hidden;
}

div#landingPage #mobileContainer {
    width: "100%";
    z-index: 0;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
}

div#landingPage form.searchAccommodations .ant-form-item-label {
    padding-bottom: 3px;
    padding-top: 3px;
}

div#landingPage form.searchAccommodations .ant-form-item { 
    margin-bottom: 5px;
}
div#landingPage form.searchAccommodations .verticalContainer {
    display: flex; flex-direction: row;
}
div#landingPage form.searchAccommodations .verticalLeftItem {flex: 1; margin-right: 5px}
div#landingPage form.searchAccommodations .verticalRightItem {flex: 1; margin-left: 5px}

div#landingPage .buttonContainer {
    margin-left: 10px; 
    margin-right: 10px; 
    margin-bottom: 10px; 
    margin-top: 10px;
}

div#landingPage .paragraphTwoLines {
    display: -webkit-box;   
    -webkit-line-clamp: 2;   
    -webkit-box-orient: vertical;     
    overflow: hidden;
}

div#landingPage .paragraphThirdLines {
    display: -webkit-box;   
    -webkit-line-clamp: 3;   
    -webkit-box-orient: vertical;     
    overflow: hidden;
}

div#landingPage div.attractionRecomendedItem .ant-image, 
div#landingPage div.accommodationRecomendedItem .ant-image {
    max-height: 60px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 6px;
}

div#landingPage div.attractionRecomendedItem .ant-card-meta-avatar, 
div#landingPage div.accommodationRecomendedItem .ant-card-meta-avatar {
    padding: 0px;
    margin-right: 7px;
}

/* smartphone icons */
#landingPage .smartphonesPlatformIcons {
    position: absolute;
    right: 0px;
    top: 100px;
    z-index: 1;
}

#landingPage.smartphoneDevice .smartphonesPlatformIcons, #landingPage.tabletDevice .smartphonesPlatformIcons {
    top: unset;
    bottom: 0px;
}

.searchAccommodationsPanel .slickSearchPage, .searchAttractionsPanel .slickSearchPage {
    min-height: 300px; 
    display: flex;
}
.searchAccommodationsPanel .slick-loading {
    background: red;
}
/* end smartphone icons */
.grayed-out {
    filter: grayscale(100%);
    opacity: 0.4;
}

div.regionsTag {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    white-space: nowrap;
    background-color: unset;
}

.containerSectionMobile div.regionsTag {
    padding-left: 10px;
}

div.regionsTag span.ant-tag {
    border-radius: 12px;
}

div.regionsTag::-webkit-scrollbar {
    display: none;
}

.containerSection {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 0px;
    background-color: rgb(240,240,240);
}

.containerSection > div {
    border-radius: 5px;
}

.containerSectionMobile {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-top: 0px;
    background-color: rgb(240,240,240);
}

.containerSectionMobile > div {
    border-radius: 0px;
}