section#attractionDescription .ant-layout-header {
    height: unset;
    line-height: 40px;
}

section#attractionDescription .ant-layout-header {
    background-color: white;
}

section#attractionDescription .ant-layout-content {
    background-color: white;
    padding: 0px;
}

section#attractionDescription .ant-layout-content h1 {
    font-size:x-large;
}

section#attractionDescription .socialMediaContainerAvatar {
    display: flex;
    flex-direction: row;
    z-index: 100;
}

section#attractionDescription .socialMediaContainerTags {
    text-align: right;
}

.attractionsDrawer .ant-drawer-body {
    padding: 0px;
}

section#attractionDescription .attractionsDrawer .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 4px;
}

.attractionsDrawer .ant-drawer-body ul {
    padding: 0px;
    background-color: rgb(255,255,255);
}

.attractionsDrawer .ant-drawer-body ul li a {
    color: rgba(0, 0, 0, 0.85);
}

.accommodationsDrawer .ant-drawer-body ul li.itemMobile a {
    font-size: 15px;
}

.accommodationsDrawer .ant-drawer-body ul li.itemMobile span.anticon
{
    font-size: 16px;
}

section#attractionDescription .attractionsDrawer ul li.ant-menu-item-active a {
    color: #1890ff;
}

section#attractionDescription .attractionsDrawer ul li.ant-menu-submenu-active .ant-menu-submenu-title .ant-menu-title-content a {
    color: #1890ff;
}

.attractionsDrawer .ant-drawer-content {
    background-color: rgb(255,255,255);
}

section#attractionDescription .accommodattractionsDrawerationsDrawer .ant-menu-inline, 
section#attractionDescription .attractionsDrawer .ant-menu-vertical, 
section#attractionDescription .attractionsDrawer .ant-menu-vertical-left {
    border-right: 0px;
}

section#attractionDescription .ant-tabs-nav::before {
    border-bottom: 0px;
}

section#attractionDescription .ant-tabs-content {
    height: 100%;
    position: unset;
}

section#attractionDescription .ant-breadcrumb-separator {
    margin-inline: 3px;
}