section#accommodationDescription {
    height: 100%;
}

section#accommodationDescription .ant-layout-header {
    height: unset;
    line-height: 40px;
}

section#accommodationDescription .ant-layout-header {
    background-color: white;
}

section#accommodationDescription .ant-layout-content {
    background-color: white;
    padding: 0px;
}

section#accommodationDescription .ant-layout-content h1 {
    font-size:x-large;
}

section#accommodationDescription .ant-list-vertical .ant-list-item-meta-title {
    margin-bottom: 0px;
}

section#accommodationDescription .bedsIcon svg {
    margin-bottom: -5px;
}

section#accommodationDescription .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, 
section#accommodationDescription .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background-color: white;
}

section#accommodationDescription .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, 
section#accommodationDescription .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #fafafa;
}

section#accommodationDescription .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, 
section#accommodationDescription .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #fafafa;
}

section#accommodationDescription .ant-pagination-item, 
section#accommodationDescription .ant-pagination-prev .ant-pagination-item-link, 
section#accommodationDescription .ant-pagination-next .ant-pagination-item-link {
    border-radius: 6px;
}

section#accommodationDescription .searchAccommodation .ant-card-cover {
    flex: 2;
    display: flex;
    overflow: hidden;
    position: relative;
}

section#accommodationDescription .searchAccommodation .ant-card-actions > li {
    margin: 3px 0;
}

section#accommodationDescription .searchAccommodation .ant-image {
    position: unset;
}

section#accommodationDescription .searchFreeTerminsAccommodations .ant-form-item-label {
    padding-bottom: 3px;
    padding-top: 3px;
}

.drawerSearchFreeTermins .ant-drawer-body {
    padding: 10px;
}

.drawerSearchFreeTermins .ant-drawer-header {
    display: none;
}

.accommodationsDrawer .ant-drawer-body {
    padding: 0px;
}

section#accommodationDescription .accommodationsDrawer .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 4px;
}

.accommodationsDrawer .ant-drawer-body ul {
    padding: 0px;
    background-color: rgb(255,255,255);
}

.accommodationsDrawer .ant-drawer-body ul li a {
    color: rgba(0, 0, 0, 0.85);
}

.accommodationsDrawer .ant-drawer-body ul li.itemMobile a {
    font-size: 15px;
}

.accommodationsDrawer .ant-drawer-body ul li.itemMobile span.anticon
{
    font-size: 16px;
}

section#accommodationDescription .accommodationsDrawer ul li.ant-menu-item-active a {
    color: #1890ff;
}

section#accommodationDescription .accommodationsDrawer ul li.ant-menu-submenu-active .ant-menu-submenu-title .ant-menu-title-content a {
    color: #1890ff;
}

.accommodationsDrawer .ant-drawer-content {
    background-color: rgb(255,255,255);
}

section#accommodationDescription .socialMediaContainerAvatar {
    display: flex;
    flex-direction: row;
    z-index: 100;
}

section#accommodationDescription .socialMediaContainerTags {
    text-align: right;
}

section#accommodationDescription .avaiableTerminsCalendar .ant-picker-calendar-header {
    display: flex;
    justify-content: center;
    padding: 12px 0;
}

section#accommodationDescription .cellAvailableTermins:hover {
    background-color: rgb(248, 248, 248);
}

section#accommodationDescription .ant-tabs-nav::before {
    border-bottom: 0px;
}

section#accommodationDescription .ant-tabs-content {
    height: 100%;
    position: unset;
}

section#accommodationDescription .ant-breadcrumb-separator {
    margin-inline: 3px;
}