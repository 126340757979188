div#mapContainer .searchModes .ant-tag-checkable {
    background-color: #fafafa;
}

div#mapContainer .criteriaList ol {
    white-space: nowrap;
    overflow-x: hidden;
    flex-wrap: nowrap;
}

div#mapContainer .searchModes .ant-tag-checkable-checked {
    background-color: #1890ff;
}

div#mapContainer .searchModes .ant-tag {
    margin-right: 3px;
}

div#mapContainer .searchComp {
    border-radius: 3px;
    overflow: hidden;
}

div#mapContainer .criteriaList::-webkit-scrollbar {
    display: none;
}

div#mapContainer .criteriaList {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.searchMapRight .ant-drawer-content, 
.itemDetailsDrawer .ant-drawer-content {
    background-color: white;
}

/** drawers **/
.searchMapRight .ant-drawer-body {
    padding: 10px;
}

.itemDetailsDrawer .ant-drawer-body {
    padding: 0px;
}

.mapDrawer .ant-drawer-body {
    padding: 0px;
}

.mapDrawer .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 4px;
}

.mapDrawer .ant-drawer-body ul {
    padding: 0px;
}

.mapDrawer .ant-drawer-content {
    background-color: rgba(0,0,0,0.5);
}

.mapDrawer .ant-drawer-body ul li.itemMobile a {
    font-size: 15px;
}

.mapDrawer .ant-drawer-body ul li.itemMobile span.anticon
{
    font-size: 16px;
}

.mapDrawer .ant-menu-inline, 
.mapDrawer .ant-menu-vertical, 
.mapDrawer .ant-menu-vertical-left {
    border-right: 0px;
}

.mapDrawer .ant-drawer-body ul li a {
    color: rgba(0, 0, 0, 0.85);
}
/** end drawers **/

div#mapContainer .moreButton .ant-spin-dot-item,
div#mapContainer .searchButton .ant-spin-dot-item,
div#mapContainer .regionButton .ant-spin-dot-item {
    background-color: #fff;
}

/*breadcrumb*/
section#accommodations .ant-breadcrumb ol {
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap;
}

/*breadcrumb*/
div#mapContainer .ant-breadcrumb-separator {
    margin-inline: 3px;
}
/*breadcrumb*/