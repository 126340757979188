@import url('accommodationCard.css');

section#accommodations .searchAccommodationsInput .ant-input:focus,
section#accommodations .searchAccommodationsInput .ant-input:active,
section#accommodations .searchAccommodationsInput .ant-input:hover {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

section#accommodations .searchAccommodationsInput .ant-btn:focus,
section#accommodations .searchAccommodationsInput .ant-btn:active,
section#accommodations .searchAccommodationsInput .ant-btn:hover {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

section#accommodations .accommodationsListContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex: 1; 
    flex-wrap: wrap;
}

section#accommodations .accommodationsListContainerCenter {
    align-items: center;
}

section#accommodations .searchAccommodationsInput .ant-input {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(247, 248, 250);
}

section#accommodations .searchAccommodationsInput .ant-input-affix-wrapper {
    background-color: rgb(247, 248, 250);
}

section#accommodations .searchAccommodationsInput > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: rgb(247, 248, 250);
    border-left: 0px;
}

section#accommodations .searchAccommodationsInput .anticon-search {
    color: rgb(43, 113, 175)
}

section#accommodations #components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}
  
section#accommodations #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}
  
section#accommodations #components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}
  
section#accommodations .site-layout .site-layout-background {
    background: #fff;
}

section#accommodations .ant-layout-content {
    min-height: unset;
}

section#accommodations .ant-layout-sider {
    background-color: white;
}

section#accommodations .ant-layout-header {
    height: unset;
    line-height: unset;
}

section#accommodations .site-layout .site-layout-background {
    background-color: #f0f2f5;
}

section#accommodations .ant-layout-sider-trigger {
    background-color: white
}

section#accommodations .site-layout .site-layout-background {
    background-color: white;
}

section#accommodations .searchAccommodations .ant-form-item-label {
    padding-bottom: 3px;
    padding-top: 3px;
}

section#accommodations .slick-slider .slick-accommodationGallery {
    top: 0px;
    bottom: unset;
    width: unset;
    left: 0px;
    right: 0px;
}

section#accommodations .criteriaList::-webkit-scrollbar {
    display: none;
}

section#accommodations .criteriaList {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

section#accommodations .criteriaList {
    -ms-touch-action: none;
    touch-action: none;
    overscroll-behavior-x: none;
}

.drawerSearchApp .ant-drawer-content{
    background-color: rgba(255, 255, 255, 1);
}

.ant-image-preview-operations .ant-image-preview-operations-operation-zoomIn,
.ant-image-preview-operations .ant-image-preview-operations-operation-zoomOut,
.ant-image-preview-operations .ant-image-preview-operations-operation-rotateRight,
.ant-image-preview-operations .ant-image-preview-operations-operation-rotateLeft {
    display: none;
}

/** end drawer **/
.drawerSearchApp .ant-drawer-body {
    padding: 10px;
}

.drawerSearchApp .ant-drawer-header,
.accommodationsDrawer .ant-drawer-header {
    padding: 0px;
}

.accommodationsDrawer .ant-drawer-body {
    padding: 0px;
}

.accommodationsDrawer .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 4px;
}

.accommodationsDrawer .ant-drawer-body ul {
    padding: 0px;
    background-color: rgb(255,255,255);
}

.accommodationsDrawer .ant-drawer-body ul li a {
    color: rgba(0, 0, 0, 0.85);
}

.accommodationsDrawer .ant-drawer-body ul li.itemMobile a {
    font-size: 15px;
}

.accommodationsDrawer .ant-drawer-body ul li.itemMobile span.anticon
{
    font-size: 16px;
}

section#accommodations .accommodationsDrawer ul li.ant-menu-item-active a {
    color: #1890ff;
}

section#accommodations .accommodationsDrawer ul li.ant-menu-submenu-active .ant-menu-submenu-title .ant-menu-title-content a {
    color: #1890ff;
}

.accommodationsDrawer .ant-drawer-content {
    background-color: rgb(255,255,255);
}

section#accommodations .accommodationsDrawer .ant-menu-inline, 
section#accommodations .accommodationsDrawer .ant-menu-vertical, 
section#accommodations .accommodationsDrawer .ant-menu-vertical-left {
    border-right: 0px;
}
/** end drawer **/

section#accommodations .searchItems input {
    background-color: rgba(0,0,0,0.005);
}

section#accommodations .ant-input-prefix {
    margin-right: 10px;
}

/*breadcrumb*/
section#accommodations .ant-breadcrumb ol {
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap;
}

section#accommodations .ant-breadcrumb-separator {
    margin-inline: 3px;
}
/*breadcrumb*/

/* pagination */
section#accommodations .ant-pagination-item, .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    border-radius: 6px;
}

section#accommodations ul.ant-pagination li.ant-pagination-item-active {
    border-color: rgba(0, 0, 0, 0.06);
    background-color: rgba(0, 0, 0, 0.06);
}

section#accommodations ul.ant-pagination li.ant-pagination-item-active a {
    color: rgba(0, 0, 0, 0.88);
}

section#accommodations .ant-pagination-next a,
section#accommodations .ant-pagination-prev a {
    text-decoration: none;
    color: rgba(0,0,0,0.88);
    font-size: 15px;
}

section#accommodations .ant-pagination-disabled a {
    color: rgba(0,0,0,0.5);
}

section#accommodations .ant-pagination .ant-pagination-item a {
    font-weight: 500;
}
/* pagination end */

/* search autocomplite */
section#accommodations.listAccommodationCardSmartphone .ant-input-group-wrapper {
    max-width: 340px;
}

section#accommodations .ant-input-group-wrapper {
    max-width: 400px;
}
/* end search autocomplite */