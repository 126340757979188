div.listAccommodationCard  {
    border: 0px;
    min-height: 200px;
    max-width: 360px;
    min-width: 285px;
    height: 240px;
    flex: 1;
    margin: 7px;
    display: flex;
    flex-direction: column;
}

div.listAccommodationCard a {
    text-decoration: none;
    color: rgba(0,0,0,0.7);
    font-size: 12px;
}

div.listAccommodationCard .ant-card-body {
    padding: 0px;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
}

div.listAccommodationCard .descriptionPage .ant-typography {
    margin-top: 10px;
}

div.listAccommodationCard .ant-card-cover {
    overflow: hidden;
    /*border-top-left-radius: 4px;
    border-top-right-radius: 4px;*/
    border-radius: 4px;
    height: 160px;
    margin-bottom: 7px;
}

div.listAccommodationCard .ant-card-cover .gallery {
    display: flex;
    width: 100%;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    height: 160px;
}

div.listAccommodationCard .ant-card-cover .ant-image-img {
    border-radius: 0px;
}

div.listAccommodationCard .ant-card-cover .ant-image {
    width: 100%;
}

div.listAccommodationCard .ant-card-head {
    min-height: unset; 
    padding: 0px 2px;
    border-bottom: unset;
}

div.listAccommodationCard .ant-card-head .ant-card-head-title {
    padding: 0px;
}

div.listAccommodationCard .tags {
    -ms-overflow-style: none;  /* IE and Edge */
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;  
    padding-top: 8px;
}

div.listAccommodationCard .tags::-webkit-scrollbar{
    display: none;
}

div.listAccommodationCard .tags span {
    border: 0px;
    color: rgba(0, 0, 0, 0.88);
    border-radius: 20px;
    cursor: pointer;
}

div.listAccommodationCard .paragraphFourLines {
    display: -webkit-box;   
    -webkit-line-clamp: 5;   
    -webkit-box-orient: vertical;     
    overflow: hidden;
    line-height: 1.4;
}

div.listAccommodationCard .paragraphFiveLines {
    display: -webkit-box;   
    -webkit-line-clamp: 5;   
    -webkit-box-orient: vertical;     
    overflow: hidden;
    line-height: 1.4;
}

div.listAccommodationCard .paragraphSixLines {
    display: -webkit-box;   
    -webkit-line-clamp: 6;   
    -webkit-box-orient: vertical;     
    overflow: hidden;
    line-height: 1.4;
}

div.listAccommodationCard .paragraphEightLines {
    display: -webkit-box;   
    -webkit-line-clamp: 8;   
    -webkit-box-orient: vertical;     
    overflow: hidden;
    line-height: 1.4;
}

div.listAccommodationCard .paragraphThreeLines {
    display: -webkit-box;   
    -webkit-line-clamp: 3;   
    -webkit-box-orient: vertical;     
    overflow: hidden;
    line-height: 1.4;
}

div.listAccommodationCard div.cardPage {
    display: flex;
    width: 100%;
    flex-wrap: wrap; 
    align-content: flex-start;
    justify-content: center;
    flex: 1;
    background-color: rgba(0,0,0,0.01);
    position: relative;
    margin-bottom: 7px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
}

div.listAccommodationCard .gallery .ant-spin-dot-item, div.listAccommodationCardListed .gallery .ant-spin-dot-item {
    background-color: rgba(0,0,0,0.2);
}

div.listAccommodationCard div.cardPage.cardPictograms, div.listAccommodationCard div.cardPage.cardQrCode, div.listAccommodationCard div.cardPage.cardTermins {
    align-content: center;
}

div.listAccommodationCardListed {
    display: flex;
    padding: 10px 0px 0px 0px;
    width: 100%;
    height: 76px;
}

div.listAccommodationCardListedFirst {
    padding: 0px;
}

div.listAccommodationCardListed .ant-card-meta-avatar {
    width: 30%;
    min-width: 123px;
    margin-right: 8px;
    overflow: hidden;
    border-radius: 4px;
}

div.listAccommodationCardListed .ant-card-meta-avatar .ant-image-img {
    border-radius: 4px;
}

div.listAccommodationCardListed .ant-card-meta-avatar .ant-skeleton.ant-skeleton-element {
    width: 100%;
}

div.listAccommodationCardListed .ant-card-meta-detail {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

section.listAccommodationCardSmartphone .accommodationsListContainer {
    background-color: #f7f7f7;
}

section.listAccommodationCardSmartphone .listAccommodationCard {
    max-width: 100%;
    min-width: 80%;
    height: unset;
    min-height: unset;
    margin-top: 0px;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 8px;
    padding: 6px;
    background-color: white;
}

section.listAccommodationCardSmartphone .listAccommodationCard:last-child {
    margin-bottom: 0px;
}

section.listAccommodationCardSmartphone .listAccommodationCard .cardPage {
    min-height: 160px;
}

section.listAccommodationCardSmartphone .listAccommodationCard .cardPage.descriptionPageNoGallery {
    min-height: unset;
}

section.listAccommodationCardSmartphone .listAccommodationCard .ant-card-cover .cardPage > a {
    width: 100%;
}