@import url('attractionCard.css');

section#attractions #components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}
  
section#attractions #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}
  
section#attractions #components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}
  
section#attractions .site-layout .site-layout-background {
    background: #fff;
}

section#attractions .ant-layout-content {
    min-height: unset;
}

section#attractions .ant-layout-sider {
    background-color: white;
}

section#attractions .ant-layout-header {
    height: unset;
    line-height: unset;
}

section#attractions .site-layout .site-layout-background {
    background-color: #f0f2f5;
}

section#attractions .ant-layout-sider-trigger {
    background-color: white
}

section#attractions .site-layout .site-layout-background {
    background-color: white;
}

section#attractions .searchAccommodations .ant-form-item-label {
    padding-bottom: 3px;
    padding-top: 3px;
}

.attractionsDrawer .ant-drawer-body {
    padding: 0px;
}

.attractionsDrawer .ant-drawer-header {
    display: none;
}

section#attractions .ant-card-actions > li {
    margin-top: 4px;
    margin-bottom: 4px;
}

section#attractions .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0px;
    line-height: 18px;
}

section#attractions .slick-slider .slick-accommodationGallery {
    top: 0px;
    bottom: unset;
    width: unset;
    left: 0px;
    right: 0px;
}

section#attractions .criteriaList::-webkit-scrollbar {
    display: none;
}

section#attractions .criteriaList {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

section#attractions .criteriaList {
    -ms-touch-action: none;
    touch-action: none;
    overscroll-behavior-x: none;
}

section#attractions .attractionCard div.ant-typography {
    margin-bottom: 0px;
}

section#attractions .attractionCard .ant-card-meta-title div.ant-typography {
    margin-bottom: 2px;
    margin-top: 3px;
}

section#attractions .threadCard {
    margin-top: 10px;
}

/** search  drawer **/

.drawerSearchApp .ant-drawer-content{
    background-color: rgba(255, 255, 255, 1);
}

.drawerSearchApp .ant-drawer-body {
    padding: 10px;
}

.drawerSearchApp .ant-drawer-header {
    display: none;
}

/** end search  drawer **/

section#attractions .attractionsListContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex: 1; 
    flex-wrap: wrap;
}

section#attractions .attractionsListContainerCenter {
    align-items: center;
}
section#attractions .attractionsDrawer .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 4px;
}

.attractionsDrawer .ant-drawer-body {
    padding: 0px;
}

.attractionsDrawer .ant-drawer-body ul {
    padding: 0px;
    background-color: rgb(255,255,255);
}

.attractionsDrawer .ant-drawer-body ul li a {
    color: rgba(0, 0, 0, 0.85);
}

.attractionsDrawer .ant-drawer-body ul li.itemMobile a {
    font-size: 15px;
}

.attractionsDrawer .ant-drawer-body ul li.itemMobile span.anticon
{
    font-size: 16px;
}

section#attractions .attractionsDrawer ul li.ant-menu-item-active a {
    color: #1890ff;
}

section#attractions .attractionsDrawer ul li.ant-menu-submenu-active .ant-menu-submenu-title .ant-menu-title-content a {
    color: #1890ff;
}

.attractionsDrawer .ant-drawer-content {
    background-color: rgb(255,255,255);
}

section#attractions .attractionsDrawer .ant-menu-inline, .attractionsDrawer .ant-menu-vertical, .attractionsDrawer .ant-menu-vertical-left {
    border-right: 0px;
}

section#attractions .searchAttractionsInput .ant-input:focus,
section#attractions .searchAttractionsInput .ant-input:active,
section#attractions .searchAttractionsInput .ant-input:hover {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

section#attractions .searchAttractionsInput .ant-btn:focus,
section#attractions .searchAttractionsInput .ant-btn:active,
section#attractions .searchAttractionsInput .ant-btn:hover {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

section#attractions .searchAttractionsInput .ant-input {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(247, 248, 250);
}

section#attractions .searchAttractionsInput .ant-input-affix-wrapper {
    background-color: rgb(247, 248, 250);
}

section#attractions .searchAttractionsInput > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: rgb(247, 248, 250);
    border-left: 0px;
}

section#attractions .searchAttractionsInput .anticon-search {
    color: rgb(43, 113, 175)
}

section#attractions .searchItems input {
    background-color: rgba(0,0,0,0.005);
}

section#attractions .ant-input-prefix {
    margin-right: 10px;
}

.ant-image-preview-operations .ant-image-preview-operations-operation-zoomIn,
.ant-image-preview-operations .ant-image-preview-operations-operation-zoomOut,
.ant-image-preview-operations .ant-image-preview-operations-operation-rotateRight,
.ant-image-preview-operations .ant-image-preview-operations-operation-rotateLeft {
    display: none;
}

/* breadcrumb */
section#attractions .ant-breadcrumb ol {
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap;
}

section#attractions .ant-breadcrumb-separator {
    margin-inline: 3px;
}

/* pagination */
section#attractions .ant-pagination-item, 
section#attractions .ant-pagination-prev .ant-pagination-item-link, 
section#attractions .ant-pagination-next .ant-pagination-item-link {
    border-radius: 6px;
}

section#attractions ul.ant-pagination li.ant-pagination-item-active {
    border-color: rgba(0, 0, 0, 0.06);
    background-color: rgba(0, 0, 0, 0.06);
}

section#attractions ul.ant-pagination li.ant-pagination-item-active a {
    color: rgba(0, 0, 0, 0.88);
}

section#attractions .ant-pagination-next a,
section#attractions .ant-pagination-prev a {
    text-decoration: none;
    color: rgba(0,0,0,0.88);
    font-size: 15px;
}

section#attractions .ant-pagination-disabled a {
    color: rgba(0,0,0,0.5);
}

section#attractions .ant-pagination .ant-pagination-item a {
    font-weight: 500;
}
/* pagination end */

/* search autocomplite */
section#attractions.listAttractionCardSmartphone .ant-input-group-wrapper {
    max-width: 340px;
}

section#attractions .ant-input-group-wrapper {
    max-width: 400px;
    background-color: rgb(247, 248, 250);
}
/* end search autocomplite */