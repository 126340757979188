div.listAttractionCard {
    max-width: 360px;
    min-width: 285px;
    height: 299px;
    min-height: 220px;
    flex: 1; 
    margin: 5px;
    border-radius: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 0px;
}

div.listAttractionCard .ant-card-head-title {
    padding: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

div.listAttractionCard a {
    font-size: 15px;
    text-decoration: none;
}

div.listAttractionCard .ant-card-head-title a {
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 1px;
    font-weight: 600;
    line-height: 1.5714285714285714;
}

div.listAttractionCard .tagsContainer {
    display: flex;
    flex-direction: row;
    align-items: center; 
    width: 100%;
    padding: 8px;
    overflow-x: auto;
    white-space: nowrap
}

div.listAttractionCard .tagsContainer span {
    border: 0px;
    color: rgba(0, 0, 0, 0.88);
    border-radius: 20px;
    cursor: pointer;
}

div.listAttractionCard .ant-card-meta-description {
    line-height: 18px;
}

div.listAttractionCard .ant-card-meta-detail .ant-card-meta-description .ant-typography {
    margin-bottom: 0px;
}

div.listAttractionCard .ant-card-meta-description .ant-typography
{
    line-height: 1.35;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-top: 0px;
}

div.listAttractionCard .ant-card-meta-description .ant-typography.paragraphFiveLines {
    -webkit-line-clamp: 5;
}

div.listAttractionCard .ant-card-meta-description .ant-typography.paragraphFourLines {
    -webkit-line-clamp: 4;
}

div.listAttractionCard .ant-card-meta-description .ant-typography.paragraphTenLines {
    -webkit-line-clamp: 10;
}

div.listAttractionCard .ant-card-meta-description .ant-typography.oneLine {
    -webkit-line-clamp: 1;
}

div.listAttractionCard .cardPage {
    display: flex; 
    flex-wrap: wrap; 
    align-content: flex-start; 
    justify-content: flex-start; 
    width: 100%;
    margin: 0px;
    flex: 1;
}

div.listAttractionCard .cardPage.qrcode, .listAttractionCard .cardPage.map {
    align-content: center; 
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 7px;
}

div.listAttractionCard .gallery .ant-spin-dot-item, div.listAttractionCardListed .gallery .ant-spin-dot-item {
    background-color: rgba(0,0,0,0.2);
}

div.listAttractionCard .ant-card-cover {
    display: flex;
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    min-height: 10px;
}

div.listAttractionCard .ant-card-cover .gallery {
    margin-bottom: 7px;
    overflow: hidden;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    height: 153px;
    width: 100%;
}

div.listAttractionCard .ant-card-body {
    flex: 1;
}

div.listAttractionCard .ant-card-body {
    padding: 0px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
}

div.listAttractionCard .ant-card-meta-description .ant-typography.paragraphFiveLines {
    -webkit-line-clamp: 5;
}

div.listAttractionCard .ant-card-meta-description .ant-typography.paragraphFourLines {
    -webkit-line-clamp: 4;
}

div.listAttractionCard .ant-card-meta-description .ant-typography.paragraphTenLines {
    -webkit-line-clamp: 10;
}

div.listAttractionCard .ant-card-meta-description .ant-typography.paragraphEightLines {
    -webkit-line-clamp: 8;
}

div.listAttractionCard .ant-card-meta-description .ant-typography.oneLine {
    -webkit-line-clamp: 1;
}

div.listAttractionCardListed {
    display: flex;
    padding: 10px 0px 0px 0px;
    width: 100%;
    height: 76px;
}

div.listAttractionCardListedFirst {
    padding: 0px;
}

div.listAttractionCard .ant-card-cover {
    display: flex;
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    min-height: 10px;
}

div.listAttractionCardListed .ant-card-meta-avatar {
    width: 30%;
    min-width: 123px;
    margin-right: 8px;
    overflow: hidden;
    border-radius: 4px;
}

div.listAttractionCardListed .ant-card-meta-avatar .ant-image-img {
    border-radius: 4px;
}

div.listAttractionCardListed .ant-card-meta-avatar .ant-skeleton.ant-skeleton-element {
    width: 100%;
}

div.listAttractionCardListed .ant-card-meta-detail {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

section.listAttractionCardSmartphone .attractionsListContainer {
    background-color: #f7f7f7;
}

section.listAttractionCardSmartphone div.listAttractionCard .ant-card-meta {
    margin: 0px;
    padding-left: 7px;
    padding-right: 7px;
}

section.listAttractionCardSmartphone div.listAttractionCard {
    max-width: 100%;
    min-width: 80%;
    height: unset;
    min-height: unset;
    margin-top: 0px;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 8px;
    background-color: white;
}

section.listAttractionCardSmartphone div.listAttractionCard .ant-card-head-title {
    margin-inline: 7px;
}

section.listAttractionCardSmartphone div.listAttractionCard:last-child {
    margin-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

section.listAttractionCardSmartphone div.listAttractionCard .ant-card-head {
    padding: 4px 7px;
}

section.listAttractionCardSmartphone div.listAttractionCard .ant-card-cover .cardPage > a {
    width: 100%;
}

section.listAttractionCardSmartphone div.listAttractionCard .ant-card-cover {
    min-height: 2px;
}

section.listAttractionCardSmartphone div.listAttractionCard .map {
    min-height: 160px;
}